import { useTranslation } from 'react-i18next';

export const useInputBoxValidation = () => {
  const { t } = useTranslation();

  const validate = (event, field, setIsValidateRequired) => {
    console.log("Validation error", field.name);
    // setIsValidateRequired(true)
   
    // if (field.inputtype === 'Text') {
    //   if (field.required === 'true' && event.target.value !== null) {
    //     event.target.setCustomValidity(t('multipleLageError') + `${field.label}`);
    //   } else {
    //     event.target.setCustomValidity('');
    //   }

    // } else if (field.name === 'docdate') {
    //     event.target.setCustomValidity(t(`docdateError`));
    // } else if (field.name === 'accid') {
    //     event.target.setCustomValidity(t(`accidError`));
    // } else if (field.name === 'name' && !event.target.value) {
    //     event.target.setCustomValidity(t(`nameError`));
    // } else if (field.name === 'mobile' && event.target.value) {
    //     const getMobiletag = document.getElementById(`${field.id}`)
    //     getMobiletag.setAttribute('pattern','[0-9]{10}')
    //     if (!/^[0-9]{10}$/.test(event.target.value)) {
    //         event.target.setCustomValidity(t('invalidMobileError'));
    //       } else {
    //         event.target.setCustomValidity('');
    //       }
    if (field.inputtype === 'Email') {
      const getEmailtag = document.getElementById(`${field.id}`)
      getEmailtag.setAttribute('pattern', '[^@]+@[^@]+\.[a-zA-Z]{2,6}')
      
      // Only validate if there is a value
      if (event.target.value) {
          if (!/[^@]+@[^@]+\.[a-zA-Z]{2,6}/.test(event.target.value)) {
              event.target.setCustomValidity(t('multipleLageError') + `${field.label}`);
          } else {
              event.target.setCustomValidity('');
          }
      } else {
          // Clear validation message if field is empty
          event.target.setCustomValidity('');
      }
  }
   // URL validation
else if (field.inputtype === 'URL') {
  const getWebsitetag = document.getElementById(`${field.id}`);
  // URL pattern remains the same
  const urlPattern = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';
  getWebsitetag.setAttribute('pattern', urlPattern);
  
  if (event.target.value) {
      if (!new RegExp(urlPattern).test(event.target.value)) {
          event.target.setCustomValidity(t('multipleLageError') + `${field.label}`);
      } else {
          event.target.setCustomValidity('');
      }
  } else {
      event.target.setCustomValidity('');
  }
}

// Number validation without decimal places
else if (field.inputtype === 'Number' && field.decimalplaces === null) {
  const getCreditDaytag = document.getElementById(`${field.id}`);
  getCreditDaytag.setAttribute('pattern', '[0-9]\\d*$');
  
  if (event.target.value) {
      if (!/^[0-9]\d*$/.test(event.target.value)) {
          event.target.setCustomValidity(t('multipleLageError') + `${field.label}`);
      } else {
          event.target.setCustomValidity('');
      }
  } else {
      event.target.setCustomValidity('');
  }
}

// Number validation with decimal places
else if (field.inputtype === 'Number' && field.decimalplaces !== null) {
  const inputElement = document.getElementById(`${field.id}`);
  const decimalPattern = `^\\d*(\\.\\d{0,${field.decimalplaces}})?$`;
  inputElement.setAttribute('pattern', decimalPattern);
  
  if (event.target.value) {
      if (!new RegExp(decimalPattern).test(event.target.value)) {
          event.target.setCustomValidity(t('multipleLageError') + `${field.label}`);
      } else {
          event.target.setCustomValidity('');
      }
  } else {
      event.target.setCustomValidity('');
  }
}

// Required field validation for general input types
else if (field.inputtype && !event.target.validate && event.target.required) {
  if (!event.target.value) {
      event.target.setCustomValidity(t('multipleLageError') + ` ${field.label}`);
      setIsValidateRequired(true);
  } else {
      event.target.setCustomValidity('');
  }
}

// Dropdown validation
else if (field.inputtype === 'Dropdown' && !event.target.validate && event.target.required) {
  if (!event.target.value) {
      event.target.setCustomValidity(t('multipleLageError') + ` ${field.label}`);
      setIsValidateRequired(true);
  } else {
      event.target.setCustomValidity('');
  }
}

// Default case
else {
  event.target.setCustomValidity('');
}
  };

  return validate;
};


