import React from 'react';
import popicon from '../../asset/popup icon.png';
import { useNavigate } from 'react-router-dom';

const CustomAlertBox = ({ 
  isOpen = false, 
  onClose = () => {}, 
  title = "Do you want to change your account?" 
}) => {
  const navigate = useNavigate(); // Initialize navigate here

  if (!isOpen) return null;
  
  const handleConfirm = () => {
    console.log('Confirmed');
    navigate('/');
  };

  const handleCancel = () => {
    console.log('Cancelled');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-sm">
        {/* Header with gradient */}
        <div className="rounded-t-lg p-0.1 flex justify-center"  style={{ background: 'linear-gradient(to right, #5fa1d6, #373195)' }}>
          <div className="w-12h-16  flex items-center justify-center">
            <img src={popicon} alt="Popup Icon" className="w-12 h-12 object-contain" />
          </div>
        </div>

        {/* Content */}
        <div className="p-6">
          {/* Text */}
          <div className="text-center mb-6">
            <p className="text-[#394867] text-lg font-medium">{title}</p>
          </div>

          {/* Buttons */}
          <div className="flex justify-end gap-3">
            <button
              onClick={handleCancel}
              className="min-w-[60px] px-4 py-1.5 text-[#394867] border border-gray-200 rounded bg-white hover:bg-gray-50"
            >
              No
            </button>
            <button
              onClick={handleConfirm}
              className="min-w-[60px] px-4 py-1.5 text-white rounded bg-[#4355BE] hover:bg-[#3a47a3]"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAlertBox;
