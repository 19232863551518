import React from 'react';
import "./reportprint.css";
import loadprint from "../../asset/Gif/Loading Print.gif";

const ReportPrintComponent = ({ reportData, template, totalRecords, onClose }) => {
  console.log("rep", reportData);
  console.log("trmp", template);

  const handlePrint = () => {
    const printContent = document.getElementById("reporttable");
    if (printContent) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              .report-table { width: 100%; border-collapse: collapse; }
              .report-table th, .report-table td { 
                border: 1px solid #ddd; padding: 8px; font-size: 15px; 
              }
              @media print {
                body * { visibility: hidden; }
                #reporttable, #reporttable * { visibility: visible; }
                #reporttable { position: absolute; top: 0; left: 0; width: 100%; }
              }
            </style>
          </head>
          <body>${printContent.outerHTML}</body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    } else {
      console.error("Element with id 'reporttable' not found");
    }
  };

  // if (!template || !template.columns) {
  //   return <div>No template or column data available</div>;
  // }

  if (!reportData || !Array.isArray(reportData) || reportData.length === 0) {
    return (
      <div>
        <img src={loadprint} alt="Loading" style={{ width: '50px' }} />
        Loading 1 to {totalRecords.totalpages} Pages
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    {/* Table Section */}
    <div style={{ flex: 1, overflow: 'auto', maxHeight: '50vh' }}>
      <table id="reporttable" className="report-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            {template.columns.map(
              (column) =>
                column?.visible && (
                  <th
                    key={column.accessor}
                    style={{
                      textAlign: column.datatype === 'Number' ? 'right' : 'left',
                      position: 'sticky',
                      top: -1,
                      backgroundColor: '#f8f8f8',
                      zIndex: 1,  // Ensures the header stays on top
                      borderBottom: '2px solid #ddd',
                      padding: '6px',
                    }}
                  >
                    {column.header}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {reportData.map((row, index) => (
            <tr key={index}>
              {template.columns.map(
                (column) =>
                  column?.visible && (
                    <td
                      key={column.accessor}
                      style={{
                        textAlign: column.datatype === 'Number' ? 'right' : 'left',
                        padding: '8px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {row[column.accessor]}
                    </td>
                  )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  
    {/* Buttons Section */}
    <hr />
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '8px',
        marginTop: '8px',
        padding: '8px',
        borderTop: '1px solid #ddd',
      }}
    >
      <button
        onClick={handlePrint}
        style={{
          padding: '4px 8px',
          background: 'linear-gradient(to right, #5fa1d6, #373195)',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Print
      </button>
        <button
        onClick={onClose}
        style={{
          padding: '4px 8px',
          background: 'linear-gradient(to right, #5fa1d6, #373195)',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Close
      </button>
      
    </div>
  </div>
  
  
  );
};

export default ReportPrintComponent;
