import { createSlice } from '@reduxjs/toolkit';

const gridSelectSlice = createSlice({
  name: 'gridselectOptions',
  initialState: {},
  reducers: {
    setgridSelectOptions: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setgridSelectOptions } = gridSelectSlice.actions;

export default gridSelectSlice.reducer;