import React, { useState } from 'react'
import './Login.css'
import { LoginPageStyle } from '../../PageStyle/pageStyleVariable'
import GoogleLogo from '../../asset/google-icon-2048x2048-czn3g8x8.png';
import signlogo from '../../asset/signimages/signlogo.png';
import { BaseURL } from '../Masters/masterPagefunctions';
import { useNavigate } from 'react-router-dom';
import CustomAlertBox from './CustomAlertBox';
function ChooseCompany() {
    const navigate = useNavigate();
    const sessionEmail = sessionStorage.getItem('email')
    const sessonUserData = JSON.parse(sessionStorage.getItem('user-data'))
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    console.log("session datas", sessionEmail, sessonUserData)

    const handleSelectCompany = (orgid) => {
        sessionStorage.setItem('orgid', orgid);
        navigate('/login', { replace: true })

    }

    const handleEmailInteraction = () => {
        setIsAlertOpen(true); // Show the custom alert box
      };
    
      const handleAlertClose = () => {
        setIsAlertOpen(false); // Close the custom alert box
      };
    return (
        <div className='h-screen'>
            <style>
                {
                    `
                    .buttonColor{
                     background-color: #372f93;
                     color:white;
                     padding:0px 20px 0px 20px;
                    }

                    .signInText{
                    color:#372f93;
                    font-size:28.9pt;
                    }

                    .companyButton{
                     background-color: white;
                    }

                     .companyButton:hover{
                     background-color: #a6a3c9;
                    }
                    `
                }
            </style>
            <div className='flex h-screen'>
                <div className='w-6/12 signBgImage h-screen sm:hidden'>

                </div>
                <div className='xl:w-6/12 lg:w-6/12 sm:w-full flex justify-center items-center'>
                    <div>
                        <div>
                            <h1 className='signformate'>Choose</h1>
                            <h1 className='signformate'>Company</h1>
                            <h6 className='signInText'>Sign In</h6>
                        </div>
                        <div className='mt-2'>
                            <form action="">
                                <div>
                                    <input className='w-[100%] h-[29px] signinputBorder' type="text" placeholder='Email Address' readOnly value={sessionEmail} style={{ backgroundColor: 'gainsboro' }}
                                        onClick={handleEmailInteraction} />
                                    {isAlertOpen && (
                                        <CustomAlertBox
                                            isOpen={isAlertOpen}
                                            onClose={handleAlertClose}
                                            // title="Confirm Account Change"
                                            // subtitle="Not you?"
                                        />
                                    )}
                                </div>

                                <div className='my-2 w-full'>
                                    {/* <button type='submit' className='buttonColor w-full'>
                                        Continue
                                    </button> */}

                                    <div className='bg-gray-100 shadow rounded px-2 py-2'>
                                        <h1 className='py-1 text-blue-900 font-bold'>Company Name</h1>
                                        <div className='grid grid-cols-3'>
                                            {sessonUserData?.map((item) => (
                                                <button className='companyButton rounded shadow flex flex-col items-center justify-center' onClick={() => handleSelectCompany(item?.orgid)} style={{ width: '75px', height: '75px', fontSize: '20px' }}>
                                                    <img src={signlogo} alt="" srcset="" style={{ width: '40px', height: '40px' }} />{item?.displayname?.substr(0, 2)}</button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='flex justify-center items-center'>
                                <hr className='w-full' />
                                <label htmlFor="" className='mx-2'>OR</label>
                                <hr className='w-full' />
                            </div>
                            <div className='flex justify-center items-center'>
                                <button className='flex justify-center items-center my-3 px-5 py-1 signShadow'>
                                    <img src={GoogleLogo} className='mr-2' alt="" width={LoginPageStyle.googleWidth} height={LoginPageStyle.googleHeight} />Continue with Google
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ChooseCompany