

export const formatListDate =(value, format)=> {
  if(value==""){
    return null
  }
 
  const [year, month, day] = value?.split('-')||[];
  switch (format) {
    case 'dd-mm-yyyy':
      return `${day}-${month}-${year}`;
    case 'dd-yyyy-mm':
      return `${day}-${year}-${month}`;
    case 'mm-yyyy-dd':
      return `${month}-${year}-${day}`;
    case 'mm-dd-yyyy':
      return `${month}-${day}-${year}`;
    case 'yyyy-mm-dd':
      return `${year}-${month}-${day}`;
    case 'yyyy-dd-mm':
      return `${year}-${day}-${month}`;


    default:
      throw new Error('Invalid format type');
  }


}
export const formatDate = (value, format) => {
  
   if(value==""){
    return null
   }

    const date = new Date(value);

  if (isNaN(date?.getTime())) {
    throw new Error('Invalid date');
  }

  const day = String(date?.getUTCDate()).padStart(2, '0');
  const month = String(date?.getUTCMonth() + 1).padStart(2, '0');
  const year = date?.getUTCFullYear();

  
  switch (format) {
    case 'dd-mm-yyyy':
      return `${day}-${month}-${year}`;
    case 'dd-yyyy-mm':
      return `${day}-${year}-${month}`;
    case 'mm-yyyy-dd':
      return `${month}-${year}-${day}`;
    case 'mm-dd-yyyy':
      return `${month}-${day}-${year}`;
    case 'yyyy-mm-dd':
      return `${year}-${month}-${day}`;
    case 'yyyy-dd-mm':
      return `${year}-${day}-${month}`;


    default:
      return `${day}-${month}-${year}`;
      
      
  }


  }

  export const formatAmount = (data,format)=> {
    
    if(data==""){
      return null
    }
    const number = parseFloat(data);
    
    if (isNaN(number)) {
        throw new Error('Invalid number');
    }
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: format?.split('.')[1]?.length || 0,
        maximumFractionDigits: format?.split('.')[1]?.length || 0
    }).format(number);
  
  }

  export const updatefield = (data, format)=> {
  
    return data.map(item => {
      let transformedItem = {...item} 
      format?.columns?.forEach(column=> {
        if(column.visible){
          const value= item[column.columnname]
         
          if(column.datatype === 'Date' && value){
            transformedItem[column.columnname] = formatDate(value, column.dataformat)
          } 
          if(column.datatype === "Number" && value){
            transformedItem[column.columnname] = formatAmount(value, column.dataformat)
          }
        }
        


      })

      return transformedItem;
    })


  }


  export const updatelistfield = (data, format)=> {
    const datelist =[ 
         "dd-mm-yyyy",
         "dd-yyyy-mm",
         "mm-dd-yyyy",
         "mm-yyyy-dd",
         "yyyy-dd-mm",
         "yyyy-mm-dd"
    ]
    const date = []
   
    return data.map(item=> {
        let transformedItem = {...item} 
        format?.forEach(column=> {
            const value= transformedItem[column.name]
           
            if( datelist.includes(column.dataformat)){
              const [datePart] = value?.split(' ')||[]

                const converteddate = formatListDate(datePart,column.dataformat) 
               
                transformedItem[column.name] = converteddate
            }
            
        })

           return transformedItem;

    })

  }








export const SubmitGridRows =(rows, initalgrid) => {
    const myrows =[...rows]
    const initalgridrow = initalgrid
    .filter(field=>field.visible)
    .sort((a,b)=>a.position -b.position)
    .reduce((acc,item)=> {
        acc[item.name] = item.initialvalue!=null && item.initialvalue!=undefined?item.inputtype=="Number"?Number(item.initialvalue):item.initialvalue:"";
        return acc
    },{})
   
    if( myrows.length>1){
        const lastObj =  myrows[ myrows.length-1];
        const isSame = JSON.stringify(lastObj) == JSON.stringify(initalgridrow)
        if(isSame){
            myrows.pop()
        }

    }
    return  myrows

}



export const removeCommonFields = (gridRow, formData) => {
  const gridKeys = Object.keys(gridRow);
  const filteredFormData = Object.keys(formData)
    .filter((key) => !gridKeys.includes(key)) // Remove keys that are in gridRow
    .reduce((acc, key) => {
      acc[key] = formData[key];
      return acc;
    }, {});
  return filteredFormData;
};


export const removeExtraFields = (gridkeys, result)=> {
  const updatedArray =  result.map(item => {
    const { modified, ...rest } = item;
    return rest;
});

  // console.log("removeExtraFields result",updatedArray)
  return updatedArray

}

// export const  evaluateExpression = (expression,rowData)=> {
//   const  evaluated = expression.replace(/tb\.(\w+)/g, (_, columnName) => rowData[columnName] || "");
//   console.log("evaluatedExpression",evaluated)
  
//   const today = new Date()
//   // console.log("todaysss",today)
//   const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
//   // console.log("formattedDate",formattedDate)
//   const finalExpression = evaluated.replace(':todate', formattedDate)
//   const datePattern = /\b\d{2}-\d{2}-\d{4}\b/g;
//   const dates = finalExpression.match(datePattern);
//   if (dates) {
//     // Convert matched dates to Date objects
//     const parsedDates = dates.map(dateStr => {
//       const [day, month, year] = dateStr.split('-');
//       return new Date(`${year}-${month}-${day}`);
//     });

//     // Calculate the difference in days if exactly two dates are found
//     if (parsedDates.length === 2) {
//       const diffInDays = Math.round((parsedDates[0] - parsedDates[1]) / (1000 * 60 * 60 * 24));
//       // console.log("Date difference:", diffInDays);
//       return diffInDays;
//     }
//   }

//   try {
//     // console.log("calc",finalExpression.replace(datePattern, ''))
//     return eval(finalExpression.replace(datePattern, ''));
//   }
//   catch(error){
//     console.error("Error evaluating expression:", error);
//      return null;
//   }

// }
function calulateExpress(expresskeys,rowdata,column,processedData){
  console.log("calRowData expresskeys",expresskeys) 
  console.log("calRowData rowdata before",rowdata)
  // console.log("calRowData rowdata column",column)
  
  console.log("calRowData rowdata processedData",processedData)
  const commonKeys = expresskeys.filter(key => key in rowdata);

  // Step 2: Extract values from rowdata for these common keys
  const values = commonKeys.reduce((acc, key) => {
    acc[key] = rowdata[key];
    return acc;
  }, {});
  if (column && column.expression) {
    try {
      // Assuming expression is in the format 'tb.duedate - tb.billdate'
      // Replace 'tb.key' with actual values from rowdata
      let expression = column.expression;
      
      // Replace the placeholders (like 'tb.duedate') with actual values from rowdata
      for (let key in values) {
        expression = expression.replace(new RegExp(`tb.${key}`, 'g'), `"${values[key]}"`);
      }

      console.log("Expression to evaluate:", expression);
      expression = expression.replace(/"(-?\d+(\.\d+)?)"/g, "$1");
      const currentDate = new Date().toISOString().split('T')[0];
      expression = expression.replace(/:todate/g, `"${currentDate}"`);
      const dateDiffMatch = expression.match(/"(\d{4}-\d{2}-\d{2})" - "(\d{4}-\d{2}-\d{2})"/);
      console.log("dateDiffMatch",dateDiffMatch)
      console.log("after express", expression)
      if (dateDiffMatch) {
        const [_, date1, date2] = dateDiffMatch;

        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);

        // Calculate difference in milliseconds and convert to days
        const diffInDays = (dateObj1 - dateObj2) / (1000 * 60 * 60 * 24);

        console.log("Calculated days difference:", diffInDays);
        return diffInDays;
      }

      // Evaluate the expression
      const result = eval(expression);  // Be cautious with eval, validate the expression before using it

      console.log("Calculated result:", result);

      // You can return or update `processedData` as needed with the result
      return result;
    } catch (error) {
      console.error("Error evaluating expression:", error);
    }
  }

  return null;

  


  // Replace tb. with context. to simulate the "tb" object in the expression
  
 

}


function convertRowData(expresskeys, rowdata,column,processedData) {

  // Iterate through each key in rowdata
  for (let key in rowdata) {
      // Check if the key exists in expresskeys
      if (expresskeys.includes(key)) {
          // If the value is a valid date string (check if it's in the format DD-MM-YYYY)
          if (rowdata[key] && rowdata[key].match(/^\d{2}-\d{2}-\d{4}$/)) {
              // Convert date to YYYY-MM-DD format
              let date = new Date(rowdata[key].split('-').reverse().join('-'));
              rowdata[key] = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
          }
          // If the value is a number (or a string that can be converted to a number)
          else if (!isNaN(rowdata[key].replace(/,/g, ''))) {
              // Remove commas if any, and convert to a number
              let number = parseFloat(rowdata[key].replace(/,/g, ''));
              // Format the number with commas as thousands separators
              rowdata[key] = number;
          }
      }
  }
  // return rowdata;
  console.log("convertRowData rowdata after",rowdata)
 let sol= calulateExpress(expresskeys,rowdata,column,processedData)
 console.log("sol",sol)
 return sol

}

export const  evaluateExpression = (expression,rowData,column)=> {
  const processedData = {...rowData}
  // console.log("evaluateExpression expression",expression)
  // console.log("evaluateExpression rowData",rowData)
  // console.log("evaluateExpression column",column)
  // const  evaluated = expression.replace(/tb\.(\w+)/g, (_, columnName) => rowData[columnName] || "");
  // console.log("evaluatedExpression",evaluated)
  
  // const today = new Date()
  // // console.log("todaysss",today)
  // const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
  // // console.log("formattedDate",formattedDate)
  // const finalExpression = evaluated.replace(':todate', formattedDate)
  // const datePattern = /\b\d{2}-\d{2}-\d{4}\b/g;
  // const dates = finalExpression.match(datePattern);
  // if (dates) {
  //   // Convert matched dates to Date objects
  //   const parsedDates = dates.map(dateStr => {
  //     const [day, month, year] = dateStr.split('-');
  //     return new Date(`${year}-${month}-${day}`);
  //   });

  //   // Calculate the difference in days if exactly two dates are found
  //   if (parsedDates.length === 2) {
  //     const diffInDays = Math.round((parsedDates[0] - parsedDates[1]) / (1000 * 60 * 60 * 24));
  //     // console.log("Date difference:", diffInDays);
  //     return diffInDays;
  //   }
  // }

  // try {
  //   // console.log("calc",finalExpression.replace(datePattern, ''))
  //   return eval(finalExpression.replace(datePattern, ''));
  // }
  // catch(error){
  //   console.error("Error evaluating expression:", error);
  //    return null;
  // }

  let  matches =  column.expression.match(/tb\.(\w+)/g);
  console.log("getexpression",matches)
  const expresskeys= matches.map(item => item.split('.').pop());
  console.log(" expresskeys", expresskeys)


console.log("processedData before",processedData)
 
  

  let converteddata =  convertRowData(expresskeys,processedData,column,processedData)
  console.log("converteddata",converteddata)
  return converteddata

  


 

}













